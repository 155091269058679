import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {DocumentService} from './document.service';
import * as moment from 'moment';
import { BudgetRoot } from '../../@types/budget-data';
import { IPetRoot } from '../../@types/pets-data';


@Injectable({
    providedIn: 'root'
})
export class AppDataService {

    personId: any = this.documentService.getWindow()?.sessionStorage.getItem('person');
    requestBody: any = {
        ambience_sale_id: 36,
        person_id: 41,
        featured: 'Y'
    };
    uuid: any = this.documentService.getWindow()?.localStorage.getItem('uuid');

    constructor(private http: HttpClient, private documentService: DocumentService) {
    }

    getDataModule(url: string, params?: any): Observable<object> {
        let obj = new HttpParams();
        if (params) {
            obj = obj.append('pessoa_id', params);
        }
        return this.http.get(`${environment.API_ENDPOINT}establishment/${url}?${obj.toString()}`);
    }

    getDataModuleBanner(category: any, max: any): Observable<object> {
        let obj = new HttpParams();
        obj = obj.set('category', category);
        obj = obj.set('max', max);
        return this.http.get(`${environment.API_ENDPOINT}establishment/banner?${obj.toString()}`);
    }

    getDataAbout(url: string, params?: any, withHtml?: any): Observable<object> {
        let obj = new HttpParams();
        if (params) {
            obj = obj.append('pessoa_id', params);
        }

        if (withHtml) {
            obj = obj.append('with_html', withHtml);
        }

        return this.http.get(`${environment.API_ENDPOINT}establishment/${url}?${obj.toString()}`);
    }

    getDatepickerRoom(params?: any): Observable<object> {
        let obj = new HttpParams();
        let httpHeaders = new HttpHeaders();

        if (params) {
            for (const key of Object.keys(params)) {
                if (params[key]) {
                    obj = obj.set(key, params[key]);
                }
            }

            if (params.pessoa_id) {
                httpHeaders = httpHeaders.append('x-hotel-request', String(params.pessoa_id));
            }
        }

        return this.http.get(`${environment.API_ENDPOINT}establishment/datepicker?${obj.toString()}`, {
            headers: httpHeaders
        });
    }

    getDatepickerRoomJson(params?: any): Observable<object> {
        let httpHeaders = new HttpHeaders();

        if (params) {
            httpHeaders = httpHeaders.append('x-hotel-id', String(params.pessoa_id));
            httpHeaders = httpHeaders.append('x-start-date', String(params.first_day));
            httpHeaders = httpHeaders.append('x-room-id', String(params.quarto_id));
        }

        return this.http.get(`${environment.DATEPICKER_REQUEST_ENDPOINT}/room`, {
            headers: httpHeaders
        });
    }

    getDatepicker(params?: any): Observable<object> {
        let obj = new HttpParams();
        let httpHeaders = new HttpHeaders();

        if (params) {
            for (const key of Object.keys(params)) {
                if (params[key]) {
                    obj = obj.set(key, params[key]);
                }
            }

            if (params.pessoa_id) {
                httpHeaders = httpHeaders.append('x-hotel-id', String(params.pessoa_id));
            }

            if (params.first_day) {
                httpHeaders = httpHeaders.append('x-start-date', params.first_day);
            }

            if (params.quarto_id) {
                httpHeaders = httpHeaders.append('x-room-id', String(params.quarto_id));
            }
        }

        return this.http.get(`${environment.DATEPICKER_REQUEST_ENDPOINT}`, {
            headers: httpHeaders
        });
    }

    getDatepickerDataJson(filePath: string): Observable<object> {
        return this.http.get(`${filePath}`);
    }

    getDataAccommodations(url: string, params: any): Observable<object> {
        let obj = new HttpParams();
        if (params) {
            obj = obj.append('pessoa_id', params);
        }

        return this.http.get(`${environment.API_ENDPOINT}establishment/${url}?${obj.toString()}`);
    }

    searchTariffs(objParams: any): Observable<object> {
        let obj = new HttpParams();
        if (objParams) {
            if (objParams.checkin) {
                obj = obj.append('checkin', objParams.checkin);
            }
            if (objParams.checkout) {
                obj = obj.append('checkout', objParams.checkout);
            }
            if (objParams.adult) {
                obj = obj.append('pax', objParams.adult);
            }
            if (objParams.ages) {
                // obj = obj.append('chd', objParams.ages.length);
                let count = 1;
                for (const age of objParams.ages) {
                    obj = obj.append(`chd[${count}]`, age);
                    count++;
                }
            }
            if (objParams.personId) {
                obj = obj.append('pessoa_id', objParams.personId);
            }
            if (objParams.rooms) {
                for (const room of objParams.rooms) {
                    if (room !== 'null') {
                        obj = obj.append('rooms[]', room);
                    }
                }
            }
            if (objParams.destinationId) {
                obj = obj.append('destination', objParams.destinationId);
            }

            if (objParams.amenities) {
                for (const amenitie of objParams.amenities) {
                    if (amenitie !== 'null') {
                        obj = obj.append('amenities[]', amenitie);
                    }
                }
            }
            if (objParams.meal_plans) {
                for (const mealPlan of objParams.meal_plans) {
                    if (mealPlan !== 'null') {
                        obj = obj.append('meal_plans[]', mealPlan);
                    }
                }
            }
            if (objParams.min_max) {
                if (objParams.min_max.min) {
                    obj = obj.append('prices[min]', objParams.min_max.min);
                }
                if (objParams.min_max.max) {
                    obj = obj.append('prices[max]', objParams.min_max.max);
                }
            }

            if (objParams.coupon) {
                obj = obj.append('coupon', objParams.coupon);
            }

            if (objParams.channel) {
                obj = obj.append('canal_id', objParams.channel);
            }

            obj.append('qtd_quarto', '1');
        }

        return this.http.get(`${environment.API_ENDPOINT}sales/search?qtd_quarto=1&${obj.toString()}`);
    }

    getDataTickets(obj: any): Observable<object> {
        return this.http.post(`${environment.OTAS_ENDPOINT}ambience-sale-tickets/availabilityDate.json`, obj);
    }

    getDataTransfers(obj: any): Observable<object> {
        return this.http.post(`${environment.OTAS_ENDPOINT}ambience-sale-transfers/availabilityDate.json`, obj);
    }

    getDataTours(obj: any): Observable<object> {
        return this.http.post(`${environment.OTAS_ENDPOINT}ambience-sale-tours/availabilityDate.json`, obj);
    }

    getDataStamps(personId?: string): Observable<object> {
        let obj = new HttpParams();
        if (personId) {
            obj = obj.append('pessoa_id', personId);
        }
        return this.http.get(`${environment.API_ENDPOINT}establishment/stamp?${obj.toString()}`);
    }

    getDataNews(): Observable<object> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/news`);
    }

    getPaymentForm(objParams: any): Observable<object> {
        let objUuid = new HttpHeaders()

        if (this.uuid) {
            objUuid = objUuid.append('uuid', this.uuid);
        }

        return this.http.post(`${environment.API_ENDPOINT}sales/payment-methods/show`, objParams, {
            headers: objUuid
        });
    }

    getDataGallery(id?: any, params?: any, limit?: any): Observable<object> {
        let obj = new HttpParams();
        if (params) {
            obj = obj.append('pessoa_id', params);
        }
        if (id) {
            return this.http.get(`${environment.API_ENDPOINT}establishment/gallery/${id}?${obj.toString()}`);
        } else {
            return this.http.get(`${environment.API_ENDPOINT}establishment/gallery?in_cover=${limit}&${obj.toString()}`);
        }
    }

    getSearchFilter(id: any, objParams:any): Observable<object> {
        let obj = new HttpParams();

        if(objParams) {
            if (objParams.checkin) {
                obj = obj.append('checkin', objParams.checkin);
            }
            if (objParams.checkout) {
                obj = obj.append('checkout', objParams.checkout);
            }
        }

        return this.http.get(`${environment.API_ENDPOINT}sales/filters/${id}?${obj.toString()}`);
    }

    postRestrictedArea(loginType: any, mail: any, password: any): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }


        if (loginType === 'client') {
            return this.http.post(`${environment.API_ENDPOINT}restricted-area/client/login`, {mail,password}, {
                headers: objParams
            });
        } else {
            return this.http.post(`${environment.API_ENDPOINT}restricted-area/corporate/login`, {mail, password}, {
                headers: objParams
            });
        }
    }

    getDestination(): Observable<object> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/destination`);
    }

    getDestinations(id: number): Observable<object> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/destination/${id}`);
    }

    getPromotion(promotionId: any, hotelId?: any): Observable<object> {
        let obj = new HttpParams();

        if (hotelId) {
            obj = obj.append('pessoa_id', hotelId);
        }

        return this.http.get(`${environment.API_ENDPOINT}establishment/promotion/${promotionId}?${obj.toString()}`);
    }

    postTestimonyForm(testimonyForm: any): Observable<object> {
        const {name, email, phone, testimony, pessoa_id} = testimonyForm;
        return this.http.post(`${environment.API_ENDPOINT}establishment/testimony`, {
            pessoa_id,
            name,
            email,
            phone,
            testimony
        });
    }

    postContactForm(contactForm: any): Observable<object> {
        const {name, mail, phone, subject, message, hotel_id} = contactForm;
        return this.http.post(`${environment.API_ENDPOINT}establishment/contact`, {
            name,
            mail,
            phone,
            subject,
            message,
            hotel_id
        });
    }

    getDataSupplements(supplementParams?: any): Observable<object> {
        let objHeaders = new HttpHeaders();
        let objParams = new HttpParams();

        if (this.uuid) {
            objHeaders = objHeaders.append('uuid', this.uuid);
        }

        if (supplementParams) {
            for (const [key, item] of supplementParams.entries()) {
                objParams = objParams.append(`items[${key}][hotel]`, item.hotelId);
                objParams = objParams.append(`items[${key}][checkin]`, item.checkIn);
                objParams = objParams.append(`items[${key}][room]`, item.quartoId);
            }
        }

        return this.http.get(`${environment.API_ENDPOINT}establishment/supplements`, {
            headers: objHeaders,
            params: objParams
        });
    }

    getDataPets(petParams: any): Observable<IPetRoot> {
        let objHeaders = new HttpHeaders();
        let objParams = new HttpParams();

        if (this.uuid) {
            objHeaders = objHeaders.append('uuid', this.uuid);
        }

        if (petParams) {
            for (const [key, item] of petParams.entries()) {
                objParams = objParams.append(`items[${key}][hotel]`, item.hotelId);
                objParams = objParams.append(`items[${key}][checkin]`, item.checkIn);
                objParams = objParams.append(`items[${key}][room]`, item.quartoId);
            }
        }

        return this.http.get<IPetRoot>(`${environment.API_ENDPOINT}establishment/pet`, {
            headers: objHeaders,
            params: objParams,
        });
    }

    getTermsPet(hotelIds: string[]): any {
        let objHeaders = new HttpHeaders();
        let objParams = new HttpParams();

        hotelIds.forEach((hotelId: string) => {
            objParams = objParams.append('hotels', hotelId);
        })


        if (this.uuid) {
            objHeaders = objHeaders.append('uuid', this.uuid);
        }

        return this.http.get(`${environment.API_ENDPOINT}establishment/pet-terms`, {
            headers: objHeaders,
            params: objParams,
        });
    }

    scroll(id: string): void {
        const element: HTMLElement | any = this.documentService.getWindow()?.document.getElementById(id);
        (element as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    getDetailGenericPages(id: string): Observable<object> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/dynamic-page/${id}`);
    }

    getGenericPagesList(id: string): Observable<object> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/landing-page/${id}`);
    }

    createSlugUrl(title: string): string {
        let slug = title.toLowerCase().trim();
        slug = slug.replace(/[^a-z0-9\s-]/g, ' ');
        slug = slug.replace(/[\s-]+/g, '-');

        return slug;
    }

    getAccommodation(id: string, hotelId?: string): Observable<object> {
        let obj = new HttpParams();

        if (hotelId) {
            obj = obj.append('pessoa_id', hotelId);
        }

        return this.http.get(`${environment.API_ENDPOINT}establishment/rooms/${id}?${obj.toString()}`);
    }

    getDataPolicy(data?: any): Observable<object> {
        let objParams = new HttpHeaders()

        if (!data) {
            data = {};
        }

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.post(`${environment.API_ENDPOINT}establishment/policy-terms`, data, {
            headers: objParams
        });
    }

    getHotels(): Observable<object> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/hotels`);
    }

    recaptchaValidador(secretKey: string, recaptcha: string): Observable<object> {
        let obj = new HttpParams();
        obj = obj.append('response', recaptcha);
        return this.http.get(`${environment.API_ENDPOINT}establishment/recaptcha?${obj.toString()}`);
    }

    getDataCep(cep: string): Observable<object> {
        return this.http.get(`${environment.BRASIL_API}cep/v1/${cep}`);
    }

    finishSale(cart: any, preservePrice?: boolean): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        if (preservePrice) {
            objParams = objParams.append('x-preserve', '1');
        }

        return this.http.post(`${environment.API_ENDPOINT}sales/finish`, cart, {
            headers: objParams
        });
    }

    getRestrictedAreaReserves(loginType: any, page?: any, selectedFilter?: any, portal?: any): Observable<object> {
        let obj = new HttpParams();

        if (page) {
            obj = obj.append('page', page);
        }
        if (selectedFilter) {
            obj = obj.append('status_id', selectedFilter);
        }

        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.get(`${environment.API_ENDPOINT}restricted-area/${loginType}/reserve?${obj.toString()}`, {
            headers: objParams
        });
    }

    getRestrictedAreaFidelity(pageVoucher?: any, pageExtract?: any): Observable<object> {
        let obj = new HttpParams();

        if (pageVoucher) {
            obj = obj.append('page_voucher', pageVoucher);
        }
        if (pageExtract) {
            obj = obj.append('page_extract', pageExtract);
        }

        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.get(`${environment.API_ENDPOINT}restricted-area/client/fidelity?${obj.toString()}`, {
            headers: objParams
        });
    }

    getBenefits(): Observable<object> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/carousel`);
    }

    getRestrictedAreaPayments(): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }
        return this.http.get(`${environment.API_ENDPOINT}restricted-area/corporate/payment`, {
            headers: objParams
        });
    }

    postRestrictedAreaGenerateVoucher(value: number): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.post(`${environment.API_ENDPOINT}restricted-area/client/voucher/generate`, {valor: value}, {
            headers: objParams
        });
    }

    postRestrictedAreaCancelReserve(reserveId: number, loginType: string): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.post(`${environment.API_ENDPOINT}restricted-area/${loginType}/booking/cancel/${reserveId}`, {},{
            headers: objParams
        });
    }

    postRestrictedAreaShareVoucher(voucherId: number, name: any, mail: any): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.post(`${environment.API_ENDPOINT}restricted-area/client/voucher/share/${voucherId}`, {
            name,
            email: mail,

        }, {
            headers: objParams
        });
    }

    postRestrictedAreaLogout(loginType: any): Observable<object> {
        let objHeaders = new HttpHeaders()

        if (this.uuid) {
            objHeaders = objHeaders.append('uuid', this.uuid);
        }

        return this.http.post(`${environment.API_ENDPOINT}restricted-area/${loginType}/logout`, null, {
            headers: objHeaders
        });
    }

    postRestrictedForgotPassword(loginType: any, email: any): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.post(`${environment.API_ENDPOINT}restricted-area/forgot-password`, {
            type: loginType === 'client' ? 0 : 1,
            email,
        }, {
            headers: objParams
        });
    }

    postRestrictedRecoverPassword(loginType: any, token: any, password: any): Observable<object> {
        let objParams = new HttpHeaders()

        if (this.uuid) {
            objParams = objParams.append('uuid', this.uuid);
        }

        return this.http.post(`${environment.API_ENDPOINT}restricted-area/forgot-password/${token}/reset`, {
            type: loginType,
            password: password,
        }, {
            headers: objParams
        });
    }

    getReciptiveById(url: string, id: number): Observable<object> {

        delete this.requestBody.featured;

        switch (url) {
            case 'tours':
                this.requestBody.tour_id = id;
                break;
            case 'transfers':
                this.requestBody.transfer_id = id;
                break;
            case 'Tickets':
                this.requestBody.ticket_id = id;
                break;
        }

        return this.http.post(`${environment.OTAS_ENDPOINT}ambience-sale-${url}/availabilityDate.json`, this.requestBody);

    }

    postWebCheckinPass(identifier: any, personId: any): Observable<object> {
        return this.http.post(`${environment.PASS_ENDPOINT}external/send-mail-checkin`, {
            identifier,
            person_id: personId
        });
    }

    preCheckin(form: object): Observable<object> {
        return this.http.post(`${environment.API_ENDPOINT}establishment/precheckin`, form);
    }

    getHotelsPreCheckIn(): Observable<object> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/precheckin/create`);
    }

    checkDates(selector: string, className: string, remove?: boolean): void {
        const elements: any = this.documentService.getWindow()?.document.querySelectorAll(selector);
        elements.forEach((element: HTMLElement) => {
            if (!remove) {
                element.classList.add(className);
            } else {
                element.classList.remove(className);
            }
        });
    }

    createWeek(days: any): any[] {
        let newWeek = [];
        const arrMonth = [];

        let count = 0;
        for (let i = 0; i <= 42; i++) {
            if (count < days.length) {
                newWeek.push(days[count]);
                count++;
            } else {
                newWeek.push(null);
            }
            if (newWeek.length === 7) {
                arrMonth.push(newWeek);
                newWeek = [];
            }
        }
        return arrMonth;
    }

    restriction(data: any, date: string, checkout: boolean, calendar: any, startDate: string): object {
        let valid = true;
        let msg = null;
        let objDates = null;
        let isUnavailable = false;

        let restriction = null;

        if (calendar) {
            if (calendar[date]) {

                restriction = calendar[date]?.restriction;

                if (date && !checkout && calendar[date]?.restriction?.rules) {

                    for (const ruleItem of calendar[date]?.restriction.rules) {
                        if (ruleItem.id === 1 || ruleItem.id === 5) {
                            // isUnavailable = true;

                            return {
                                isValid: false,
                                message: ruleItem.message_full,
                                dates: objDates,
                                isUnavailable
                            }
                        }

                        if (ruleItem.id === 3) {
                            // isUnavailable = true;

                            return {
                                isValid: false,
                                message: ruleItem.message_full,
                                dates: objDates,
                                isUnavailable
                            }
                        }
                    }
                }
            }
        }

        if (data) {
            switch (data.id) {
                case 1:
                    valid = (checkout) ? true : false;
                    break;
                case 3:
                    valid = (checkout) ? true : false;
                    break;
                case 4:
                    valid = (checkout) ? false : true;
                    break;
                case 5:
                    valid = (checkout) ? true : false;
                    break;
            }
        }

        if (checkout) {
            const diff = moment(date).diff(startDate, 'days');
            let datesMessage = '';
            let count = 0;
            const arrDate = [];

            if (calendar) {
                if (calendar[date]?.restriction?.closed) {
                    for (let i = 1; i <= (diff); i++) {
                        const newDate = moment(startDate, 'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD');

                        if (calendar[newDate]?.restriction?.closed) {
                            const dateFormatted = moment(newDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
                            count++;
                            arrDate.push(dateFormatted);
                        }
                    }

                    if (count > 0 && arrDate.length > 0) {
                        if (count === 1) {
                            return {
                                isValid: true,
                                message: null,
                                dates: null,
                                isUnavailable
                            }
                        }

                        arrDate.shift();

                        valid = false;
                        msg = `As seguintes data(s) ${arrDate.join(', ')} estão indisponíveis para checkout`;
                        objDates = {
                            start: moment().format('YYYY-MM-DD'),
                            end: moment().add(1, 'days').format('YYYY-MM-DD')
                        };
                    }

                    return {
                        isValid: false,
                        message: msg,
                        dates: objDates,
                        isUnavailable
                    }
                }

                if (calendar[startDate]?.restriction?.rules) {
                    for (const ruleItem of calendar[startDate]?.restriction.rules) {
                        if (ruleItem.id === 2) {

                            const days = Number(ruleItem.message_full.match(/\d+/)[0]);
                            valid = moment(date).diff(startDate, 'days') >= days;
                            if (!valid) {
                                msg = ruleItem.message_full;
                            }
                        }
                    }
                }

                if (calendar[date]?.restriction?.rules) {
                    for (const ruleItem of calendar[date]?.restriction.rules) {
                        if (ruleItem.id === 4) {
                            return {
                                isValid: false,
                                message: ruleItem.message_full,
                                dates: objDates,
                                isUnavailable: true
                            }
                        }
                    }
                }

                for (let i = 1; i <= (diff - 1); i++) {
                    const newDate = moment(startDate, 'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD');

                    if (calendar[newDate]?.restriction?.rules) {
                        for (const ruleItem of calendar[newDate]?.restriction.rules) {
                            if (ruleItem.id === 1 || ruleItem.id === 5) {
                                datesMessage += !count ? moment(newDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : `, ${moment(newDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}`;
                                count++;
                                arrDate.push(newDate);
                            }
                        }
                    }

                    if (calendar[newDate]?.restriction?.closed) {
                        datesMessage += !count ? moment(newDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : `, ${moment(newDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}`;
                        count++;
                        arrDate.push(newDate);
                    }
                }

                if (count > 0 && arrDate.length > 0) {
                    valid = false;
                    msg = `As seguintes data(s) ${datesMessage} estão indisponíveis ou esgotadas`;
                    objDates = {
                        start: moment().format('YYYY-MM-DD'),
                        end: moment().add(1, 'days').format('YYYY-MM-DD')
                    };
                }
            }
        }

        return {
            isValid: valid,
            message: msg,
            dates: objDates,
            isUnavailable
        };
    }

    showAskSuites(type: boolean): void {
        const asksuite: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.infochat_floatmain');

        if (asksuite) {
            if (type) {
                (asksuite as HTMLElement).classList.add('zIndexZero');
            } else {
                (asksuite as HTMLElement).classList.remove('zIndexZero');
            }
        }
    }

    checkIconService(id: string | any, flag?: boolean): string {
        let assets = 'assets/images/';
        switch (id) {
            case 2:
                assets += 'ico-cartao-credito.svg';
                break;
            case 15:
                assets += 'ico-deposito-bancario.svg';
                break;
            case 24:
                assets += 'ico-cartao-credito.svg';
                break;
            case 26:
                assets += 'ico-pagamento-balcao.svg';
                break;
            case 31:
                assets += 'ico-dinheiro.svg';
                break;
            case 35:
                assets += 'ico-dinheiro.svg';
                break;
            case 38:
                assets += (flag) ? 'icon-logo-pix.svg' : 'logo-pix.svg';
                break;
            case 40:
                assets += (flag) ? 'icon-logo-ame.svg' : 'logo-ame.svg';
                break;
            case 41:
                assets += (flag) ? 'icon-logo-pix.svg' : 'logo-pix.svg';
                break;
            default:
                assets += 'ico-dinheiro.svg';
                break;
        }

        return assets;
    }

    getBudgetDataWithToken(reserveToken: string): Observable<BudgetRoot> {
        let params = new HttpParams();
        params = params .set('token', reserveToken);

        return this.http.get<BudgetRoot>(`${environment.EXTRANET_CRS_ENDPOINT}/oportunidades/resumo?${params.toString()}`);
    }

    getBudgetData(budgetId: any): Observable<object> {
        let objUuid = new HttpHeaders()

        if (this.uuid) {
            objUuid = objUuid.append('uuid', 'ea756d1bd860187a61f354c6ff8982ad');
        }

        const body = {
            "hotels": {
                "977": {
                    "payment": {
                        "form_id": 38,
                        "payment_id": 2742
                    },
                    "items": [
                        {
                            "sale_id": 1122830,
                            "price": 100,
                            "cancelation_id": 504,
                            "installments": null
                        }
                    ]
                }
            }
        }

        return this.http.post(`${environment.API_ENDPOINT}services/add-payment`, body, {
            headers: objUuid
        });
    }

    getFaqs(): Observable<object> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/faq`);
    }

    getLeads(): Observable<any> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/leads`);
    }

    sendLead(leadData: any): Observable<any> {
        return this.http.post(`${environment.API_ENDPOINT}establishment/leads/trigger`, leadData);
    }

    getFloatWidgets(): Observable<any> {
        return this.http.get(`${environment.API_ENDPOINT}establishment/float-widgets`)
    }

    hasNumberDdi(numero: string): boolean {
        const numberFormat = numero.replace(/[\s()-]/g, '');
        if (numberFormat.length > 11) {
            const ddiCheck = numberFormat.slice(0, 3);
            const ddiValid = parseInt(ddiCheck, 10);
            return ddiValid >= 1 && ddiValid <= 999;
        }
        return false;
    }

    getDynamicPages(type: any, viewer?: any, id?: any): Observable<any> {
        const url = `${environment.API_ENDPOINT}establishment/dynamic-page/${type}` +
        `${viewer ? `/${viewer}` : ''}` +
        `${id ? `/${id}` : ''}`;

        return this.http.get(url);
    }

}
