import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
import {MatExpansionModule} from '@angular/material/expansion';

import {RouterModule} from '@angular/router';
import {TagListComponent} from './tag-list/tag-list.component';
import {HighlighterComponent} from './highlighter/highlighter.component';
import {ResourcesComponent} from './resources/resources.component';
import {LoadingComponent} from './loading/loading.component';
import {CounterComponent} from './counter/counter.component';
import {TestimonyComponent} from './testimony/testimony.component';
import {BenefitsComponent} from './benefits/benefits.component';
import {ImagesComponent} from './images/images.component';
import {ConfigRoomComponent} from './config-room/config-room.component';
import {AvailableHotelItemComponent} from './available-hotel-item/available-hotel-item.component';
import {InfoComponent} from './info/info.component';
import {PopoverComponent} from './popover/popover.component';
import {CartComponent} from './cart/cart.component';
import {CashbackComponent} from './cashback/cashback.component';
import {VideoPlayerComponent} from './video-player/video-player.component';
import {BannersComponent} from './banners/banners.component';
import {PopoverCartComponent} from './popover-cart/popover-cart.component';
import {ItemComponent} from './cart/item/item.component';
import {MatRippleModule} from '@angular/material/core';
import {ToursComponent} from './tours/tours.component';
import {RateComparatorComponent} from './rate-comparator/rate-comparator.component';
import {OurHotelsComponent} from './our-hotels/our-hotels.component';
import {InnerLoadComponent} from './inner-load/inner-load.component';
import {DatepickerComponent} from './datepicker/datepicker.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import { DialogCartComponent } from './dialog-cart/dialog-cart.component';
import {ItemDialogCartComponent} from './dialog-cart/item/item.component';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DialogResourcesComponent} from './resources/dialog/dialog.component';
import { TriggersComponent } from './triggers/triggers.component';
import { BrandsComponent } from './brands/brands.component';
import { RoomComponent } from './room/room.component';
import { GaleryModalComponent } from './galery-modal/galery-modal.component';
import { PromotionCounterComponent } from './promotion-counter/promotion-counter.component';
import {CancelationComponent} from './cancelation/cancelation.component';
import { TestimonyFormComponent } from './testimony-form/testimony-form.component';
import { MatSelectModule } from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { DefaultSnackBarComponent } from './default-snack-bar/default-snack-bar.component';
import { ListNewsComponent } from './list-news/list-news.component';
import { ListPromotionsComponent } from './list-promotions/list-promotions.component';
import { ShowAllComponent } from './show-all/show-all.component';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DialogPolicyComponent } from './dialog-policy/dialog-policy.component';
import {RecaptchaModule} from 'ng-recaptcha';
import { TermsComponent } from './terms/terms.component';
import { DialogFinishComponent } from './dialog-finish/dialog-finish.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { RestrictedAreaClientComponent } from './restricted-area-client/restricted-area-client.component';
import { CookieComponent } from './cookie/cookie.component';
import { WebcheckinComponent } from './webcheckin/webcheckin.component';
import { RoomEventsComponent } from './room-events/room-events.component';
import { RestrictedAreaLoginComponent } from './restricted-area-login/restricted-area-login.component';
import { DialogForgotPasswordComponent } from './dialog-forgot-password/dialog-forgot-password.component';
import {NgxMaskModule} from 'ngx-mask';
import { SocialsComponent } from './socials/socials.component';
import { BtnWhatsAppComponent } from './btn-whats-app/btn-whats-app.component';
import { FloatingBannerComponent } from './floating-banner/floating-banner.component';
import { MySearchesComponent } from './my-searches/my-searches.component';
import { TranslateComponent } from './translate/translate.component';
import { SlideGalleryComponent } from '../features/shared/slide-gallery/slide-gallery.component';
import { PrecheckinComponent } from './precheckin/precheckin.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ModalInfoRoomComponent } from './room/modal-info-room/modal-info-room.component';
import { BannerSearchComponent } from './banner-search/banner-search.component';
import { InternPageTranslateComponent } from './intern-page-translate/intern-page-translate.component';
import { PrivacyPolicyLandingPageComponent } from './privacy-policy-landing-page/privacy-policy-landing-page.component';
import { FaqComponent } from './faq/faq.component';
import { SanitizePipe } from '../pipes/sanitize.pipe';
import { SanitizeHtmlPipe } from '../pipes/sanitize-html.pipe';
import { DialogErrorPriceDivergencyComponent } from './dialog-error-price-divergency/dialog-error-price-divergency.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { HeroTextComponent } from './campaigns/components/hero-text/hero-text.component';
import { CampaignFormComponent } from './campaigns/components/campaign-form/campaign-form.component';
import { MatMenuModule } from '@angular/material/menu';
import { LeadBannerComponent } from './campaigns/components/lead-banner/lead-banner.component';
import { CardCampaignComponent } from './card-campaign/card-campaign.component';
import { DialogConfirmResponsibleComponent } from './dialog-confirm-responsible/dialog-confirm-responsible.component';
import { CalculateDatesDiffPipe } from '../pipes/calculate-dates-diff.pipe';

@NgModule({
    declarations: [
        TagListComponent,
        HighlighterComponent,
        ResourcesComponent,
        LoadingComponent,
        CounterComponent,
        TestimonyComponent,
        BenefitsComponent,
        ImagesComponent,
        ConfigRoomComponent,
        AvailableHotelItemComponent,
        InfoComponent,
        PopoverComponent,
        CartComponent,
        CashbackComponent,
        VideoPlayerComponent,
        BannersComponent,
        PopoverCartComponent,
        ItemComponent,
        ToursComponent,
        RateComparatorComponent,
        OurHotelsComponent,
        InnerLoadComponent,
        DatepickerComponent,
        DialogCartComponent,
        ItemDialogCartComponent,
        DialogErrorComponent,
        DialogResourcesComponent,
        TriggersComponent,
        BrandsComponent,
        RoomComponent,
        GaleryModalComponent,
        PromotionCounterComponent,
        CancelationComponent,
        TestimonyFormComponent,
        DefaultSnackBarComponent,
        ListNewsComponent,
        ListPromotionsComponent,
        ShowAllComponent,
        RestrictedAreaClientComponent,
        CookieComponent,
        RecaptchaComponent,
        PrivacyPolicyComponent,
        DialogPolicyComponent,
        TermsComponent,
        DialogFinishComponent,
        WebcheckinComponent,
        RoomEventsComponent,
        RecaptchaComponent,
        PrivacyPolicyComponent,
        DialogPolicyComponent,
        RestrictedAreaClientComponent,
        RestrictedAreaLoginComponent,
        DialogForgotPasswordComponent,
        SocialsComponent,
        BtnWhatsAppComponent,
        FloatingBannerComponent,
        MySearchesComponent,
        TranslateComponent,
        SlideGalleryComponent,
        PrecheckinComponent,
        ModalInfoRoomComponent,
        BannerSearchComponent,
        InternPageTranslateComponent,
        PrivacyPolicyLandingPageComponent,
        FaqComponent,
        SanitizePipe,
        SanitizeHtmlPipe,
        CalculateDatesDiffPipe,
        DialogErrorPriceDivergencyComponent,
        CampaignsComponent,
        HeroTextComponent,
        CampaignFormComponent,
        LeadBannerComponent,
        CardCampaignComponent,
        DialogConfirmResponsibleComponent,
    ],
    imports: [
        // vendor
        CommonModule,
        RouterModule,
        FormsModule,
        // material
        MatButtonModule,
        MatTabsModule,
        NgxUsefulSwiperModule,
        MatRippleModule,
        MatInputModule,
        MatIconModule,
        MatDialogModule,
        MatTooltipModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatCheckboxModule,
        RecaptchaModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        FormsModule,
        NgxMaskModule,
        MatDividerModule,
        MatDatepickerModule,
        MatExpansionModule,
        Ng2TelInputModule,
        MatMenuModule,
    ],
    exports: [
        // vendor
        CommonModule,
        RouterModule,
        // material
        MatButtonModule,
        MatTabsModule,
        MatInputModule,
        MatMenuModule,
        // local
        TagListComponent,
        HighlighterComponent,
        ResourcesComponent,
        LoadingComponent,
        CounterComponent,
        TestimonyComponent,
        BenefitsComponent,
        ImagesComponent,
        ConfigRoomComponent,
        AvailableHotelItemComponent,
        InfoComponent,
        PopoverComponent,
        CartComponent,
        CashbackComponent,
        VideoPlayerComponent,
        BannersComponent,
        ToursComponent,
        RateComparatorComponent,
        OurHotelsComponent,
        InnerLoadComponent,
        DatepickerComponent,
        PopoverCartComponent,
        DialogCartComponent,
        DialogErrorComponent,
        TriggersComponent,
        BrandsComponent,
        RoomComponent,
        GaleryModalComponent,
        PromotionCounterComponent,
        CancelationComponent,
        TestimonyFormComponent,
        DefaultSnackBarComponent,
        ListNewsComponent,
        ListPromotionsComponent,
        ShowAllComponent,
        CookieComponent,
        PrivacyPolicyComponent,
        RecaptchaComponent,
        TermsComponent,
        WebcheckinComponent,
        RoomEventsComponent,
        SocialsComponent,
        BtnWhatsAppComponent,
        MySearchesComponent,
        TranslateComponent,
        SlideGalleryComponent,
        PrecheckinComponent,
        BannerSearchComponent,
        InternPageTranslateComponent,
        CampaignsComponent,
        SanitizePipe,
        SanitizeHtmlPipe,
        CalculateDatesDiffPipe,
        CardCampaignComponent,
        MatCheckboxModule,
    ],
    entryComponents: [
        DialogResourcesComponent,
        DialogPolicyComponent,
        DialogCartComponent,
        DialogErrorComponent,
        DialogFinishComponent,
        FloatingBannerComponent
    ]
})
export class SharedModule {
}
