import {RestrictedAreaLoginComponent} from './../../../shared/restricted-area-login/restricted-area-login.component';
import {Component, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild, ViewChildren} from '@angular/core';
import {InitializerService} from '../../../services/initializer.service';
import {MatSidenav} from '@angular/material/sidenav';
import {SeoService} from '../../../services/seo.service';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {isPlatformBrowser, Location} from '@angular/common';
import {DocumentService} from '../../../services/document.service';
import {Observable} from 'rxjs';
import { filter } from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import { DialogClientTypeComponent } from '../../../features/restricted-area/dialog-client-type/dialog-client-type.component';

declare var gtag: any;
declare var fbq: any;

declare global {
    interface Window { dataLayer: any[]; }
}

@Component({
    selector: 'my-portal-init',
    templateUrl: './init.component.html',
    styleUrls: ['./init.component.scss'],
})
export class InitComponent implements OnInit {

    data: any;
    load: boolean = true;
    submenuExpandedIndex: any;
    subscriptionClose: any;
    userLogged: boolean = false;
    userData: any = this.documentService.getWindow()?.sessionStorage?.getItem('userData')
        ? JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '')
        : null;
    @ViewChild('sidenav', {static: false}) public sidenav!: MatSidenav;
    isMobile: boolean = this.initializerService.isMobileDevice();
    isDisableSite: any
    // @Output() closedStart: Observable<void>;
    showSimplifiedHeader: boolean = false;

    constructor(
        private initializerService: InitializerService,
        private seoService: SeoService,
        private router: Router,
        private location: Location,
        private documentService: DocumentService,
        public dialog: MatDialog,
        private activatedRouter: ActivatedRoute,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {

        router.events.subscribe((val) => {
            if (location.path() === '') {
                // if (this.documentService
                //     .getWindow()
                //     ?.sessionStorage.getItem('finishSale')) {
                //     this.documentService
                //         .getWindow()
                //         ?.sessionStorage.removeItem('finishSale');
                // }

                if (isPlatformBrowser(this.platformId)) {
                    if (
                        this.documentService
                            .getWindow()
                            ?.sessionStorage.getItem('color')
                    ) {
                        // document.documentElement.style.setProperty('--theme-client-color', sessionStorage.getItem('color'));
                        const color: any = this.documentService
                            .getWindow()
                            ?.sessionStorage?.getItem('color');
                        this.documentService
                            .getWindow()
                            ?.document.documentElement.style.setProperty(
                            '--theme-client-color',
                            color
                        );
                    }
                }
                // this.ngOnInit();
                // this.getDataInitializer();
            }


        });

        // this.router.events.pipe(
        //     filter(event => event instanceof NavigationEnd)
        // ).subscribe((event: any | NavigationEnd) => {
        //     /** START : Code to Track Page View  */
        //     this.gtag('event', 'page_view', {
        //         page_path: event.urlAfterRedirects
        //     });
        //     /** END */
        // });

        const routerEvents = router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        );

        routerEvents.subscribe((event: any | NavigationEnd)  => {
            if (isPlatformBrowser(this.platformId)) {
                if((<any> window).gtag) {
                    gtag('event', 'page_view', {
                        'page_path': event.urlAfterRedirects
                    });
                }
                if ((<any> window).fbq) {
                    fbq('init', this.data?.marketing?.facebook_pixelid);
                    fbq('track', 'PageView');
                }
            }
        });

        this.activatedRouter.queryParams
            .pipe(filter(params => {
                const shouldOpenDialog = params?.open_restricted_area === 'true';
                const includedClientTypes = ['client', 'corporate'];
                const isValidUserType = includedClientTypes.includes(params?.user_type);

                return shouldOpenDialog && isValidUserType;
            }))
            .subscribe(params => {
                this.dialog.open(RestrictedAreaLoginComponent, {
                    data: {
                        loginType: params?.user_type
                    },
                    width: '500px',
                });
            }
        );
    }


    ngOnInit(): void {
        this.activatedRouter.queryParams.subscribe((params: any) => {
             (this.location.path().includes('checkout') && params.uuid_oportunidade) ? this.showSimplifiedHeader = true : this.showSimplifiedHeader = false;
        });

        this.initializerService.currentData
            .subscribe((data) => {
                this.data = data;
            })
            .unsubscribe();
        const urlSearchParams = new URLSearchParams(this.documentService
            .getWindow()?.location.search);

        const personId = (urlSearchParams.get('pessoa_id')) ? urlSearchParams.get('pessoa_id') : null;
        const temporaryId = (urlSearchParams.get('pessoa_id')) ? urlSearchParams.get('temporary_id') : null;

        const uuid = (urlSearchParams.get('uuid')) ? urlSearchParams.get('uuid') : null;
        const opportunityUuid = (urlSearchParams.get('uuid_oportunidade')) ? urlSearchParams.get('uuid_oportunidade') : null;
        const affiliate = (urlSearchParams.get('affiliate')) ? urlSearchParams.get('affiliate') : null;
        const coupon = (urlSearchParams.get('cupom')) ? urlSearchParams.get('cupom') : null;
        const recoveryCart = (urlSearchParams.get('recovery_cart')) ? urlSearchParams.get('recovery_cart') : null;

        if (recoveryCart) {
            this.documentService.getWindow()?.localStorage?.removeItem('cart');
        }

        if(coupon) {
            this.documentService
                .getWindow()
                ?.sessionStorage?.setItem('coupon', coupon);
        }

        if (personId) {
            this.documentService
                .getWindow()
                ?.localStorage?.removeItem('uuid');
        }

        if (uuid) {
            this.documentService
                .getWindow()
                ?.localStorage?.setItem('uuid', uuid);
        }

        if (affiliate) {
            this.documentService
                .getWindow()
                ?.sessionStorage?.setItem('affiliate', affiliate);
        }

        const params = {
            personId,
            temporaryId
        }


        this.initializerService.getInitializer(params).subscribe((response: any) => {

            this.data = response.data;
            this._updateData(this.data);
            this.documentService
                .getWindow()
                ?.sessionStorage?.setItem('person', `${this.data.id}`);
            this.documentService
                .getWindow()
                ?.sessionStorage?.setItem('color', `#${this.data.color}`);
            this.documentService
                .getWindow()
                ?.sessionStorage?.setItem('typeSearch', this.data.type);
            this.documentService
                .getWindow()
                ?.localStorage?.setItem('uuid', `${this.data.session_uuid}`);
            const color: any = this.documentService
                .getWindow()
                ?.sessionStorage?.getItem('color');
            this.documentService
                .getWindow()
                ?.document.documentElement.style.setProperty(
                '--theme-client-color',
                color
            );

            if (this.data?.seo) {
                this.seoService.updateSeo(this.data);
            }

            this.isDisableSite = this.data.error
            this.load = false;

            if (uuid && !opportunityUuid) {
                this.router.navigate(['/sale']);
            }

            if (this.data?.marketing?.google_tag_manager) {
                this.addGTMScript(this.data.marketing.google_tag_manager);
            }

            if (this.data?.marketing?.google_analytics?.geral) {
                this.addGAScript(this.data.marketing.google_analytics.geral);
            }

            if (this.data?.marketing?.google_ads) {
                this.addGTAGScript(this.data.marketing.google_ads);
            }

            if (this.data?.marketing?.facebook_pixelid) {
                this.addFBPixel(this.data.marketing.facebook_pixelid);
            }

            if (this.data.asksuite) {
                this.askSuite(this.data.asksuite);
            }

            setTimeout(() => {
                this.initializerService.setSidenav(this.sidenav);
            }, 25);

        });

        this.initializerService.currentControlAuthGuard.subscribe((data: any) => {
            this.userLogged = data;

            this.userData = this.documentService.getWindow()?.sessionStorage?.getItem('userData')
                ? JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '')
                : null;

            this.userLogged = this.userData?.id ? true : false;
        });
    }

    _updateData(data: []): void {
        this.initializerService.changeInitializerData(data);
    }

    toogleToRight(): void {
        this.initializerService.toggle();
    }

    openSubmenu(index: any): void {
        if (index === null) {
            this.submenuExpandedIndex = null;
            return;
        }

        if (this.submenuExpandedIndex === index) {
            this.submenuExpandedIndex = null;
        } else {
            this.submenuExpandedIndex = index;
        }
    }

    submenuExpanded(ind: any): boolean {
        return this.submenuExpandedIndex === ind;
    }

    onClose(): void {
        setTimeout(() => {
            this.submenuExpandedIndex = null;
        }, 400);
    }

    openDialog(): void {
        if (this.userLogged) {
            if (this.userData?.type === 'client') {
                this.router.navigateByUrl('/area-restrita/cliente');
            } else {
                this.router.navigateByUrl('/area-restrita/corporativo');
            }
        } else {
            const dialogRef = this.dialog.open(DialogClientTypeComponent, {
                width: '300px',
                data: { },
            });

            dialogRef.afterClosed().subscribe(result => { });
        }
    }

    addGAScript(trackingId: string): void {
        const gaScript = this.documentService.getWindow()?.document.createElement('script');
        (gaScript as HTMLScriptElement).setAttribute('defer', 'true');
        (gaScript as HTMLScriptElement).setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingId}`);

        const gaScript2 = this.documentService.getWindow()?.document.createElement('script');
        (gaScript2 as HTMLScriptElement).innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingId}\', { 'debug_mode':true });`;
        this.documentService.getWindow()?.document.head.prepend((gaScript as HTMLScriptElement));
        this.documentService.getWindow()?.document.head.prepend((gaScript2 as HTMLScriptElement));
    }

    addGTMScript(trackingId: string): void {
        const GTMScript: HTMLScriptElement | any = this.documentService.getWindow()?.document.createElement('script');
        GTMScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${trackingId}');`;
        this.documentService.getWindow()?.document.head.prepend(GTMScript);

        const GTMiFrame: HTMLIFrameElement | any = this.documentService.getWindow()?.document.createElement('noscript');
        GTMiFrame.innerText = `<iframe src="https://www.googletagmanager.com/ns.html?id=${trackingId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        this.documentService.getWindow()?.document.body.append(GTMiFrame);
    }

    addGTAGScript(trackingId: string): void {
        const gtagScript: HTMLScriptElement | any = this.documentService.getWindow()?.document.createElement('script');
        gtagScript.setAttribute('async', 'true');
        gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingId}`)

        const gtagScript2: HTMLScriptElement | any = this.documentService.getWindow()?.document.createElement('script');
        gtagScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${trackingId}');`;
        this.documentService.getWindow()?.document.head.prepend((gtagScript as HTMLScriptElement));
        this.documentService.getWindow()?.document.head.prepend((gtagScript2 as HTMLScriptElement));
    }

    addFBPixel(trackingId: string): void {
        const fbPixel: HTMLScriptElement | any = this.documentService.getWindow()?.document.createElement('script');
        fbPixel.innerText = `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window,document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '${trackingId}'); fbq('track', 'PageView');`

        const fbPixelNoScript: HTMLScriptElement | any = this.documentService.getWindow()?.document.createElement('noscript');
        fbPixelNoScript.innerText = `<img height="1" width="1" src="https://www.facebook.com/tr?id=${trackingId}&ev=PageView&noscript=1"/>`
        this.documentService.getWindow()?.document.head.prepend((fbPixel as HTMLScriptElement));
        this.documentService.getWindow()?.document.head.prepend((fbPixelNoScript as HTMLScriptElement));
    }

    askSuite(code: string): void {
        const scriptAsk: HTMLScriptElement | any = this.documentService.getWindow()?.document.createElement('script');
        scriptAsk.defer = true;
        scriptAsk.id = 'script-infochat';
        scriptAsk.class = 'asksuite-position';
        scriptAsk.src = `https://cdn.asksuite.com/infochat.js?dataConfig=https://control.asksuite.com/api/companies/${code}`;
        this.documentService.getWindow()?.document.body.prepend(scriptAsk);
    }
}
